































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import { FundProjectYearSummaryDto } from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
  },
})
export default class CreateFundProjectYearSummary extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: FundProjectYearSummaryDto = {
    id: 0,
    year: 2021,
  };
  year = "2021";
  btnDisabled = false;

  created() {
    if (this.$route.params.id) {
      api.fundProjectYearSummaryService
        .get({ id: Number(this.$route.params.id) })
        .then((res) => {
          this.form = { ...res };
          this.year = String(this.form.year);
        });
    }
    if (this.$route.params.projectId) {
      this.form.fundProjectId = Number(this.$route.params.projectId);
    }
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (!this.year) {
          this.$message.error("年份必填");
          return;
        }
        this.form.year = Number(this.year);
        if (this.form!.id) {
          await api.fundProjectYearSummaryService.update({
            body: this.form,
          });
        } else {
          await api.fundProjectYearSummaryService.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("操作成功");
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    serviceArea: [
      {
        required: true,
        message: "服务区域必填",
        trigger: "blur",
      },
    ],
    benefitNumber: [
      {
        required: true,
        message: "受益人数必填",
        trigger: "blur",
      },
    ],
    activityEffect: [
      {
        required: true,
        message: "活动效果必填",
        trigger: "blur",
      },
    ],
    year: [
      {
        required: true,
        message: "年份必填",
        trigger: "blur",
      },
    ],
    summarization: [
      {
        required: true,
        message: "概述必填",
        trigger: "blur",
      },
    ],
  };
}
